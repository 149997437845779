.color-lightblack {
  color: #797a76;
}

hr {
  border-top: 2px solid black;
}

.grid-toolbox {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
}

.dzu-dropzone {
  margin: 0 0;
}

.landing-background {
  background: url(/adino-background.jpg) no-repeat;
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.qr-link {
  background: #da3f20dd;
  padding: 10px 30px 10px 10px;
  color: white;
  position: relative;
  margin: 0px 20px 20px;
}

.qr-link-secondary {
  background: #a7232add;
}

.qr-link.disabled {
  opacity: .8;
}

.qr-link h5 {
  margin: 0;
}

.qr-link .cursor {
  position: absolute;
  right: 10px;
}

.qr-logo-large-container {
  text-align: right;
}
.qr-logo-large {
  height: 300px;
}

.tbl-prod th {
  text-align: center;
  padding: 0.75rem .4rem;
}

.tbl-prod {
  font-size: .8em;
}

.production-protocol-sheet {
  width: 800px;
}

.tbl-prod-result {
  padding: 0 !important;
}

.tbl-prod input {
  font-size: 1.0em;
  width: 100%;
}

.tbl-prod input.space-right {
  width: 75%;
  margin-right: 2px;
}

.tbl-prod td {
  padding: 0.4rem;
  vertical-align: middle;
}

.tbl-prod .input-number {
  text-align: right;
  font-family: 'Courier New', Courier, monospace;
}

.accordion-docs .card-header {
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0 !important;
}

.accordion-docs .card {
  border: 0;
  border-radius: 0;
}

.accordion-docs .card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem;
}

.accordion-docs .card-header .btn-block:focus {
  box-shadow: transparent;
}

.accordion-docs .card-header .btn-block {
  border: 0;
  border-radius: 0;
  font-size: 14px;
  padding: 0.6rem 0.75rem;
  background-color: #6c757d;
  color: white;
}

.accordion-docs .card-header .btn-block.collapsed {
  background-color: transparent;
  color: #182026;
}

.accordion-docs .card-header .btn-block .icon-collapse {
  font-size: 20px;
  display: inline-block;
}
.accordion-docs .card-header .btn-block.collapsed .icon-collapse {
  display: none;
}
.accordion-docs .card-header .btn-block .icon-expand {
  font-size: 20px;
  display: none;
}
.accordion-docs .card-header .btn-block.collapsed .icon-expand {
  display: inline-block;
}

.article-title {
  color: #da3f20dd;
  font-weight: bold;
}

.article-page-table th {
  width: 12rem;
}
.article-page-table th,
.article-page-table td {
  padding: 0.25em;
}

.article-page-table ul {
  padding-left: 0;
  list-style: none;
  margin-left: 0;
}
.article-page-table li {
  padding-left: 1em;
  text-indent: -1em;
}
.article-page-table li:before {
  content: "+";
  padding-right: 5px;
}


.navbar {
  background-color: #e7e7e4 !important;
  border-bottom: 1px solid black;
}

.login-container {
  background-color: #e7e7e4dd;
  padding: 1rem;
  border-radius: 1rem;
}
