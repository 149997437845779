.article-search-box .card-header {
  padding: 0.5rem .5rem;
  background-color: #da3f20dd;
  color: white;
  font-weight: bold;
}

.article-search-box .card-body {
  padding: 0.5rem .5rem;
}

.article-search-box .dashes {
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
  margin: 0.2rem 0;
}

.article-search-box .filter-link {
  display: block;
  color: #797a76;
}

.article-search-box .filter-link:hover,
.article-search-box .filter-link.active {
  color: #da3f20dd;
}

.global-search-field-container {
  position: relative;
}
.global-search-field-container form {
  width: 100%;
}
.global-search-field-container .material-icons {
  position: absolute;
  top: 4px; 
  left: 6px;
}
.global-search-field-container input {
  padding-left: 36px;
  width: 100%;
}
